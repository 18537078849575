import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../components/Typography/Typography";
import MainLayout from "../components/mainlayout";
import { mobileViewBreakpoint } from "../components/helper";
import Text from "../components/Text/Text";
import Summary from "../components/CaseStudy/Summary/Summary";

const useStyles = makeStyles(theme => ({
  container: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 80px auto",
    "& p": {
      marginBottom: "20px",
      color: "rgba(0,0,0,0.6)",
      fontSize: "20px",
      lineHeight: "30px"
    },
    "& > h2": {
      marginBottom: "28px",
      color: "#122044",

      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 40px 16px",
      margin: "80px auto 0px auto"
    }
  },
  root: {
    margin: "0px -16px"
  }
}));
const Article = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "home/eventtransport10.jpg" }) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Event Transportation Management Services"}
      h1
      banner={
        <StaticImage
          src="../assets/images/home/eventtransport10.jpg"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Event Transportation Service" }]}
      metaTitle={"Event Transportation Management Services"}
      shareImage={data.bannerImage.childImageSharp.fixed.src}
      metaDescription={
        "MOTUS | ONE aims to provide the best event transportation management services. Click to learn how we deliver the highest level of service to our clients."
      }
    >
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography component={"h2"} customVariant={"h2Bold"}>
            Event transportation as it should be done
          </Typography>
          <Typography customVariant={"bodyLargeRegular"}>
            We specialize in event transport and management. No project is too
            large or small, and we partner closely to configure services that
            satisfy your exact requirements and minimize spend.
          </Typography>
          <Typography customVariant={"bodyLargeRegular"}>
            Our local expertise and strong use of technology keep operations
            efficient while maximizing your returns. Alongside this, our
            customer-first mentality keeps passengers safe, comfortable and
            reassured.
          </Typography>
        </div>

        <Summary
          title={"Simplify your event transport management challenges"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../assets/images/events/EVENT_TRANSPORT_SIMPLIFY.jpg"
              draggable="false"
            />
          }
          customHeight={800}
          link={undefined}
          description={
            <>
              <p>
                We offer end-to-end transport management and support for events.
                You can deploy our services in any model that fulfills your
                needs – from the sole and executive provider of services to
                bespoke, individual service delivery.
              </p>
              <p>
                Our goal is always to realize business benefits for you. So, our
                solutions always focus on cost reduction, maximizing revenue,
                and helping ops teams run at maximum efficiency.
              </p>
              <p>
                We go the extra mile to enhance the customer experience. We put
                a strong emphasis on staff training and team-building that
                unites and motivates staff. It’s our way to ensure a
                customer-first mindset that exceeds passenger expectations.
              </p>
            </>
          }
        />

        <Summary
          title={"Technology that gets results"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../assets/images/events/EVENT_TRANSPORT_TECHNOLOGY.png"
              alt="motus one rde transport application"
              draggable="false"
              objectFit="contain"
            />
          }
          customHeight="500"
          link={undefined}
          description={
            <>
              <p>
                We combine our proprietary technology with strategic industry
                partnerships to equip teams and vehicles with systems that
                deliver measurable results.
              </p>
              <p>
                Track your vehicles in real-time, and take control with ingress
                and regress metrics, guest-monitoring and staff KPI reporting.
                Our systems enable you to optimize every aspect of event
                transport and can scale for events of global size or local
                significance.
              </p>
              <p>
                We equip drivers, passengers, and ops teams with the tools they
                need to communicate and respond to rapidly changing
                circumstances. These tools deliver unprecedented new
                efficiencies in booking, route-planning, fleet management and
                ground operations.
              </p>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
